@font-face {
  font-family: OCR;
  src: url(../OCR.TTF);
}
.card__name {
  font-size: clamp(14px, 4vw, 30px);
  bottom: clamp(20px, 30px, 20px);
  left: clamp(25px, 2vw, 20px);
  opacity: 0.7;
}
.card__number {
  font-size: clamp(14px, 5vw, 35px);
  text-align: center;
  bottom: 28%;
  font-family: OCR;
  opacity: 0.7;
}
.body-background{
 --tw-bg-opacity: 1;
  background-color: rgb(43, 43, 43);
}
.button-text-color{
 --tw-text-opacity: 1;
 color: rgb(43, 43, 43/var(--tw-text-opacity)); 
}
.card__expiry {
  font-size: clamp(14px, 4vw, 32px);
  text-align: center;
  width: 100%;
  bottom: 18%;
  font-family: OCR;
  opacity: 0.7;
}
.card__code {
  font-family: OCR;
  right: clamp(60px, 8vw, 100px);
  font-size: clamp(18px, 3vw, 30px);
  opacity: 0.8;
}
@media only screen and (min-width: 768px) {
  .card__name {
    font-size: clamp(14px, 3vw, 30px);
  }
  .card__number {
    font-size: clamp(14px, 3.5vw, 35px);
  }
  .card__expiry {
    font-size: clamp(14px, 3vw, 28px);
  }
  .card__code {
    font-size: clamp(18px, 2.5vw, 30px);
  }
}

@media only screen and (max-width: 350px) {
  .card__name {
    font-size: clamp(8px, 4vw, 30px);
    bottom: clamp(5vw, 20px, 10px);
    left: clamp(5vw, 3vw, 20px);
  }
  .card__number {
    font-size: clamp(12px, 4vw, 35px);
  }
  .card__expiry {
    font-size: clamp(10px, 3.5vw, 25px);
  }
  .card__code {
    font-size: clamp(10px, 2.5vw, 25px);
    right: clamp(20px, 8vw, 50px);
  }
}
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #fff;
}
input:checked ~ .dot_bg {
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}
